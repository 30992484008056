.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput {
  width: 100%;
  background: transparent;
}

.DateRangePickerInput_arrow {
  width: 10%;
}

.DateRangePickerInput_calendarIcon {
  width: 10%;
  margin: 0 !important;
}

.DateRangePickerInput__withBorder {
  border: none;
  border-radius: 0;
}

.DateInput {
  width: 40%;
  background: transparent;
}

.DateInput_input {
  background: transparent;
  box-sizing: border-box;
  font-size: 16px;
  height: 32px;
}

.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid var(--c-blue);
  border-left: 0;
}

.DateInput_fang {
  display: none;
}

.CalendarDay__selected_span {
  background: #00d4ff;
  border: 1px double #00b4f0;
  color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #00b4f0;
  border: 1px double #00b4f0;
  color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--c-blue);
  border: 1px double var(--c-blue);
  color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #00d4f0;
  border: 1px double #00b4f0;
  color: #0054e0;
}

.CalendarDay__hovered_span:active {
  background: #00b4f0;
  border: 1px double #00b4f0;
  color: #0054e0;
}