@import '~normalize.css';

:root {
  --c-accent-primary-light: #F4F2FF;
  --c-brand: #372e61;
  --c-dark: #0b081a;
  --c-light: #f2f2f7;
  --c-surface: #3c3948;
  --c-white: #ffffff;
  --c-red: #f74e2f;
  --c-red-alpha: rgba(247, 78, 47, 0.05);
  --c-blue: #0074e0;
  --c-blue-alpha: rgba(0, 116, 224, 0.05);
  --c-yellow: #f7f5ea;
  --c-yellow-alpha: rgba(173, 159, 47, 0.1);;
  --c-gray100: #0b081a;
  --c-gray90: #232131;
  --c-gray80: #3c3948;
  --c-gray70: #54525f;
  --c-gray50: #6f6d78;
  --c-gray50-alpha: rgba(111, 109, 120, 0.5);
  --c-gray40: #8f8e94;
  --c-gray20: #cecdd1;
  --c-gray10: #e4e3e8;
  --c-gray8: #595959;
  --c-gray5: #f2f2f7;
}

body {
  background-color: var(--c-gray5);
  min-width: 1000px;
  font-family: 'Noto Sans JP' !important;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
